<template>
  <div class="app flex-row align-items-center min-vh-100 bg-light">
    <div class="container text-center">
      <b-row class="justify-content-center">
        <b-col md="8" lg="6">
          <div class="maintenance-message">
            <!-- Maintenance Header -->
            <h1 class="display-3 font-weight-bold text-primary">
              Under Maintenance
            </h1>
            <h4 class="pt-3">We're working on something great!</h4>
            <p class="text-muted lead">
              The page you're looking for is currently undergoing maintenance.
            </p>
            <p class="text-muted lead mt-4">
              For any questions, please contact us:
            </p>
            <p class="font-weight-bold text-primary">+ 1-800-915-2127</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
};
</script>

<style scoped>
.maintenance-message {
  padding: 30px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 4rem;
}

h4 {
  font-size: 1.5rem;
}

.text-muted {
  font-size: 1.1rem;
}

.mt-4 {
  margin-top: 2rem;
}

.font-weight-bold {
  font-weight: bold;
}

.text-primary {
  color: #007bff;
}
</style>
